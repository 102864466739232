import React, { useState, useCallback, useEffect, useRef } from "react";
import { FaSearch, FaBars, FaTimes, FaSignInAlt, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import logo from '../assets/images/homescholing.png';
import { Link, useNavigate } from 'react-router-dom';
import { usePageViewAnalytics } from "../hooks/usePageViewAnalytics";

const Mainheader = () => {
  usePageViewAnalytics();

  const [menuOpen, setMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [isVideoMenuOpen, setIsVideoMenuOpen] = useState(false);
  const [isFreeResourcesOpen, setIsFreeResourcesOpen] = useState(false); // Track if free resources menu is open
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const freeResourcesRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
      if (freeResourcesRef.current && !freeResourcesRef.current.contains(event.target)) {
        setIsFreeResourcesOpen(false);
      }
    };

    if (menuOpen || isFreeResourcesOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen, isFreeResourcesOpen]);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [menuOpen]);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleClick = () => {
    alert("Coming Soon!");
  };

  const togglePhoneNumber = () => {
    setShowEmail((prev) => !prev);
  };

  const handleVideoMenuClick = () => {
    setIsVideoMenuOpen(prev => !prev);
  };

  const handleVideoNavigation = (videoPath) => {
    setIsVideoMenuOpen(false);
    navigate(videoPath);
  };

  const handleCloseMenu = useCallback(() => {
    setIsVideoMenuOpen(false);
  }, []);

  React.useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.video-dropdown') && !event.target.closest('.video-menu-button')) {
        handleCloseMenu();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [handleCloseMenu]);

  const handleFreeResourcesHover = () => {
    setIsFreeResourcesOpen(true);
  };

  const handleFreeResourcesLeave = () => {
    setIsFreeResourcesOpen(false);
  };

  return (
    <>
      {/* Top Navbar */}
      <header className="fixed top-0 left-0 right-0 z-20 flex justify-between items-center p-4 bg-blue-500 shadow-md w-full">
        {/* Left Side: Hamburger and Logo */}
        <div className="flex items-center w-full md:w-auto">
          {/* Animated Hamburger and Cross Icon for Mobile */}
          <div className="md:hidden mr-4">
            <button onClick={toggleMenu} className="focus:outline-none">
              {menuOpen ? (
                <FaTimes className="text-white text-2xl transition-all duration-300 ease-in-out" />
              ) : (
                <FaBars className="text-white text-2xl transition-all duration-300 ease-in-out" />
              )}
            </button>
          </div>

          {/* Logo and Center Text */}
          <div className="flex-1 flex items-center justify-start text-left">
            <Link to="/">
              <img src={logo} alt="Logo" className="h-16 w-16 mr-3 object-contain" />
            </Link>
            <span className="hidden md:block text-white text-lg leading-tight ml-4">
              Empowering Home Schooling – Free, Quality Resources for Every Learner.
            </span>
          </div>

        </div>

        {/* Call Icon Right-Aligned on Mobile */}
        <div className="sm:hidden absolute right-4 flex items-center cursor-pointer" onClick={togglePhoneNumber}>
          <FiMail className="text-xl text-white" />
          <a href="mailto:homeschoolingwithmbu@gmail.com" className="text-lg text-white ml-1">
            Email
          </a>
        </div>

        {/* Phone Number Display */}
        {showEmail && (
          <div className="absolute top-12 right-4 bg-white text-black p-2 rounded shadow-lg animate-pulse">
            homeschoolingwithmbu@gmail.com
          </div>
        )}

        {/* Search, Login, Signup (only visible on desktop) */}
        <div className="hidden md:flex items-center space-x-6 relative mr-4">
          <div className="flex items-center cursor-pointer" onClick={() => setIsSearchOpen(!isSearchOpen)}>
            <FaSearch className="text-white hover:text-yellow-400" />
            <span className="text-white hover:text-yellow-400 ml-2">Search</span>
          </div>
          {isSearchOpen && (
            <input
              type="text"
              placeholder="Search..."
              className="ml-2 p-2 border border-gray-300 rounded-md"
              onBlur={() => setIsSearchOpen(false)}
            />
          )}
          <button onClick={handleClick} className="text-white hover:text-yellow-400 flex items-center space-x-1">
            <FaSignInAlt className="h-5 w-5" />
            <span>Login</span>
          </button>
          <button onClick={handleClick} className="bg-yellow-500 text-blue-950 py-2 px-4 rounded-md">
            Signup
          </button>
        </div>
      </header>

      {/* Fixed Secondary Navbar */}
      <nav className="bg-white shadow-md fixed top-24 left-0 w-full h-12 z-40">
        <div className="hidden md:flex justify-around bg-white py-4 shadow-md">
          <a href="/" className="text-blue-600 hover:text-blue-500">Home</a>
          <a href="/talk-ai" className="text-blue-600 hover:text-blue-500">Talk with Ai </a>
          <a href="/preschool" className="text-blue-600 hover:text-blue-500">Preschool</a>
          <a href="/weekly-plan" className="text-blue-600 hover:text-blue-500">Weekly Plans</a>
          <a href="/jolly-phonics" className="text-blue-600 hover:text-blue-500">Jolly  Phonics</a>
          <a href="/islamic-gallery" className="text-blue-600 hover:text-blue-500">Islamic Gallery</a>
          <a href="/star-of-the-month" className="text-blue-600 hover:text-blue-500">Stars of the month</a>
          <a href="/parents-feedback" className="text-blue-600 hover:text-blue-500">What Parents Say</a>
          <a href="/learning-toys" className="text-blue-600 hover:text-blue-500">Learning Toys</a>

          {/* Free Resources Dropdown */}
          <div
            className="relative"
            onMouseEnter={handleFreeResourcesHover}
            onMouseLeave={handleFreeResourcesLeave}
            ref={freeResourcesRef}
          >
            <a className="text-blue-600 hover:text-blue-500 flex items-center cursor-pointer">
              Free Resources {isFreeResourcesOpen ? <FaChevronUp className="ml-1" /> : <FaChevronDown className="ml-1" />}
            </a>
            {isFreeResourcesOpen && (
              <div className="absolute top-full w-48 bg-white border border-gray-300 rounded-md shadow-lg">
                <ul className="py-1">
                  <li className="px-4 py-2 hover:bg-gray-100">
                    <a href="/printable" className="block text-blue-600 hover:text-blue-500">Free Printables</a>
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-100">
                    <a href="/games" className="block text-blue-600 hover:text-blue-500">Free Learning Games</a>
                  </li>
                  <li className="px-4 py-2 hover:bg-gray-100">
                    <a href="/curriculum" className="block text-blue-600 hover:text-blue-500">Free Curriculum & Books</a>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <a href="/videos" className="text-blue-600 hover:text-blue-500">MBU's Videos</a>
        </div>
      </nav>

      {/* Mobile Slide-out Menu */}
      <div
        ref={menuRef}
        className={`fixed top-24 left-0 h-full max-h-[80vh] bg-white text-gray-700 w-3/4 transform transition-transform duration-300 ease-in-out overflow-y-auto ${menuOpen ? "translate-x-0" : "-translate-x-full"} md:hidden z-50`}
      >
        <div className="p-4 space-y-4">
          <a href="/" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">Home</a>
          <a href="/talk-ai" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">Talk with Ai</a>
          <a href="/preschool" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">Preschool</a>
          <a href="/weekly-plan" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">Weekly Plans</a>
          <a href="/jolly-phonics" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">Jolly Phonics</a>
          <a href="/islamic-gallery" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">Islamic Gallery</a>
          <a href="/printable" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">Free Printable</a>
          <a href="/star-of-the-month" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">Stars of the month</a>
          <a href="/parents-feedback" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">What Parents Say</a>
          <a href="/games" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">Free Learning Games</a>
          <a href="/learning-toys" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">Learning Toys</a>
          <a href="/pics" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">MBU's Pictures</a>
          <a href="/videos" className="block hover:text-blue-500 border-b border-gray-300 pb-2 pt-2">MBU's Videos</a>
        </div>
      </div>
    </>
  );
};

export default Mainheader;