import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { testimonials } from "../assets/data/ParentsFeedback";

const Testimonial = () => {
  return (
    <div className="max-w-4xl mx-auto mt-12 mb-12 px-6 relative">

      <div className="text-center md:text-left md:ml-12 mt-48 mb-12">
        <h1 className="text-2xl font-bold mb-2">  What Parents Say</h1>
        <span className="block w-32 h-1 bg-gradient-to-r from-blue-500 to-purple-500 mt-1 mb-4 mx-auto md:mx-0"></span>
      </div>
      <Swiper
        pagination={{ clickable: true }}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper !pb-14" // Ensures space for pagination dots
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index} className="flex justify-center items-center">
            <div className="relative max-w-lg w-full p-6 bg-white rounded-2xl shadow-xl border border-gray-200 transform hover:scale-105 transition-transform duration-300 mx-auto">
              <p className="text-lg text-gray-700 italic text-justify">
                "{testimonial.review}"
              </p>
              <div className="mt-6 text-center">
                <h3 className="text-xl font-semibold text-gray-900">
                  {testimonial.name}
                </h3>
                <p className="text-gray-500">
                  {testimonial.city} - Age {testimonial.age}
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* Swiper Pagination Dots Outside the Slides */}
      <div className="swiper-pagination !bottom-0 absolute left-1/2 transform -translate-x-1/2"></div>
    </div>
  );
};

export default Testimonial;
