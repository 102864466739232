import React, { useState } from "react";

const JoliPhonicComponent = ({ title, path, thumbnail, audioUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="max-w-xs w-full sm:w-1/2 md:w-1/3 lg:w-1/4 bg-white shadow-xl rounded-lg overflow-hidden p-4 m-2 flex flex-col items-start border border-gray-200">
      {/* Image with click handler to open modal */}
      <img
        src={thumbnail}
        alt={`HomeSchooling with MBU JOlly phonic ${title}`}
        className="w-full h-48 object-cover cursor-pointer"
        onClick={openModal}
      />

      {/* Audio Player */}
      <div className="w-full mt-2">
        <audio controls className="w-full">
          <source src={audioUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>

      {/* Modal for Full-Size Image */}
      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={thumbnail}
              alt={`HomeSchooling with MBU JOlly phonic ${title}`}
              className="w-full h-auto max-h-[80vh] object-contain"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-white text-2xl bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-75"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default JoliPhonicComponent;