import React, { useState } from "react";
import SchoolTypeComponent from "../components/SchoolTypeComponent";
import ScrollToTopButton from "../components/scrollTopButton";
import HomeCarouselModal from "../components/HomeCarouselModal";

const Home = () => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className="p-0">
      <div className="bg-blue-100 mt-12">
        <div className="container mx-auto p-4">
          <h1 className="text-4xl font-bold mt-32 text-blue-900">
            What is Homeschooling?
          </h1>
          <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-6 items-start">
            {/* Video goes first on mobile but will be in the second column on larger screens */}
            <video
              src={"https://res.cloudinary.com/dgybxrkh2/video/upload/v1731388154/profile_ao39l5.mp4"}
              alt="Homeschooling video"
              controls
              className="rounded-md shadow-md order-1 md:order-2"
            />

            {/* Text content */}
            <p className="text-lg order-2 md:order-1">
              Homeschooling means teaching your child at home, where you can guide them closely and help them learn in fun, hands-on ways. Instead of just paying high school fees, you can buy learning toys and activities that will actually help your child grow.

              Homeschooling lets you teach through real-life experiences. For example, when you go to the mall, let your child pay using the ATM. When buying groceries, give them cash to pay. Take them to parks and play areas, or set up activities at home to help them learn.

              From ages 2 to 6, children need your time and attention. With homeschooling, they learn through play and everyday activities, rather than just from books. This makes learning more fun and meaningful!
            </p>
          </div>

          <h2 className="text-4xl font-bold mt-12 text-blue-900">
            Homeschooling Daily Routine: A Complete Guide to Your Child’s Activities
          </h2>
          <p className="text-lg mt-5">
            Creating a structured daily routine for your child’s homeschooling can help them develop positive habits, a sense of security, and a love for learning. Here’s a full-day routine to follow, with each activity contributing to their development in different ways:
          </p>
          <h2 className="text-2xl font-bold text-gray-800 mb-4 mt-4">Morning Routine</h2>

          {/* <!-- Morning Routine --> */}
          <div className="mb-6">
            <h3 className="text-xl font-bold text-gray-700">1. Waking Up</h3>
            <p className="text-gray-600 mt-2">
              Start the day by teaching your child a simple morning prayer or dua, such as
              <span className="font-semibold italic"> Alhamdulillah</span> (Praise be to God) for waking up. This instills gratitude and positivity from the start.
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-bold text-gray-700">2. Hygiene Time</h3>
            <p className="text-gray-600 mt-2">
              <span className="font-semibold">Teeth Brushing:</span> Guide them in brushing their teeth, explaining the importance of cleanliness.
            </p>
            <p className="text-gray-600 mt-2">
              <span className="font-semibold">Face Washing and Comb Hair:</span> Encourage them to wash their face and comb their hair to promote good hygiene.
            </p>
          </div>

          {showMore && <div>
            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">3. Breakfast Together</h3>
              <p className="text-gray-600 mt-2">
                Serve a healthy breakfast, like eggs, oatmeal, or fruit. Use this time to introduce simple concepts like colors, counting (how many slices or fruits), and food names.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">4. Morning Prayer</h3>
              <p className="text-gray-600 mt-2">
                If you practice prayers, teach your child to pray with you. This can be a peaceful start to the day and a moment of bonding.
              </p>
            </div>

            {/* <!-- Learning Activities --> */}
            <h2 className="text-2xl font-bold text-gray-800 mb-4 mt-6">Learning Activities</h2>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">5. Circle Time (9:00 am)</h3>
              <p className="text-gray-600 mt-2">
                <span className="font-semibold">Learning Songs:</span> Sing alphabet, counting, or color songs together to make learning fun.
              </p>
              <p className="text-gray-600 mt-2">
                <span className="font-semibold">Calendar and Weather Talk:</span> Discuss the day of the week, date, and weather. You could create a simple calendar where they can mark the day with a sticker.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">6. Story Time (9:30 am)</h3>
              <p className="text-gray-600 mt-2">
                Read a story with colorful pictures. This improves their listening skills, vocabulary, and imagination. Ask simple questions to ensure they understand and engage with the story.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">7. Sensory Play (10:00 am)</h3>
              <p className="text-gray-600 mt-2">
                Activities like playing with sand, water, or clay are great for sensory development. You can also create sensory bins with rice, small toys, or colored pasta.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">8. Activity Worksheets or Drawing (10:30 am)</h3>
              <p className="text-gray-600 mt-2">
                Use worksheets for coloring, drawing, or basic counting. Let them draw freely or color shapes; it develops their fine motor skills and creativity.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">9. Snack Break (11:00 am)</h3>
            </div>

            {/* <!-- Mid-Day Activities --> */}
            <h2 className="text-2xl font-bold text-gray-800 mb-4 mt-6">Mid-Day Activities</h2>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">10. Learning Games (11:30 am)</h3>
              <p className="text-gray-600 mt-2">
                <span className="font-semibold">Matching Games:</span> Use cards or objects to teach colors, shapes, and numbers by matching similar items.
              </p>
              <p className="text-gray-600 mt-2">
                <span className="font-semibold">Building Blocks or Puzzles:</span> These activities help improve problem-solving skills, concentration, and coordination.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">11. Outdoor Time or Physical Exercise (12:00 pm)</h3>
              <p className="text-gray-600 mt-2">
                Take them outside for a walk, to a park, or even play in the backyard. Simple exercises like jumping, running, or balancing are great for their physical health and energy release.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">12. Lunch (1:00 pm)</h3>
              <p className="text-gray-600 mt-2">
                Involve your child in simple tasks like setting the table. Teach them basic table manners, and let them serve themselves to encourage independence.
              </p>
            </div>

            {/* <!-- Afternoon Routine --> */}
            <h2 className="text-2xl font-bold text-gray-800 mb-4 mt-6">Afternoon Routine</h2>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">13. Quiet Reading or Picture Book Time (3:30 pm)</h3>
              <p className="text-gray-600 mt-2">
                Let them explore picture books independently. This quiet time is good for relaxing and learning at their own pace.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">14. Educational Activities (4:00 pm)</h3>
              <p className="text-gray-600 mt-2">
                <span className="font-semibold">Practical Life Skills:</span> Teach simple life skills, like folding clothes or tidying up toys, to encourage responsibility.
              </p>
              <p className="text-gray-600 mt-2">
                <span className="font-semibold">Counting and Sorting:</span> Use everyday objects (like fruits, spoons, or toys) to teach counting and sorting by size, color, or type.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">15. Art and Craft (4:30 pm)</h3>
              <p className="text-gray-600 mt-2">
                Encourage creativity with simple crafts, like making shapes with playdough or finger painting. Let them express themselves freely.
              </p>
            </div>

            {/* <!-- Evening Routine --> */}
            <h2 className="text-2xl font-bold text-gray-800 mb-4 mt-6">Evening Routine</h2>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">16. Snack Time and Talk (5:00 pm)</h3>
              <p className="text-gray-600 mt-2">
                Serve a light snack and chat with your child about their day, reinforcing language and communication skills.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">17. Outdoor Play or Park Visit (5:30 pm)</h3>
              <p className="text-gray-600 mt-2">
                Physical play outdoors is essential for their health. Activities like swinging, sliding, or running help with their coordination and social interaction.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">18. Practical Learning Activity (6:00 pm)</h3>
              <p className="text-gray-600 mt-2">
                <span className="font-semibold">Shopping Practice:</span> If you’re going to the grocery store, let them hand over money or pay at the counter. It’s a great way to introduce basic money concepts.
              </p>
            </div>

            {/* <!-- Night Routine --> */}
            <h2 className="text-2xl font-bold text-gray-800 mb-4 mt-6">Night Routine</h2>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">19. Dinner Time (7:30 pm)</h3>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-bold text-gray-700">20. Bedtime Routine (8:00 pm)</h3>
              <p className="text-gray-600 mt-2">
                Include a bedtime story or a gentle conversation about the day to help them wind down and reflect on their day.
              </p>
            </div>
          </div>}
          <button
            onClick={() => setShowMore(!showMore)}
            className="text-blue-500 underline"
          >
            {showMore ? 'Show Less' : 'Read More'}
          </button>
        </div>
      </div>
      <HomeCarouselModal />
      <SchoolTypeComponent />
      <ScrollToTopButton />
    </div>
  );
};

export default Home;
