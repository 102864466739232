import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { FaMicrophone } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoStop, IoSend } from "react-icons/io5";

const TalkWithAi = () => {
  const apiEndpoint = "https://generativelanguage.googleapis.com/v1/models/gemini-1.5-pro:generateContent";
  const apiKey = process.env.REACT_APP_GEMINI_API_KEY;
  const [prompt, setPrompt] = useState("");
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [typingResponse, setTypingResponse] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  let typingInterval = useRef(null);
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

  // Scroll to bottom when a new message is added or typing is done
  useEffect(() => {
    if (!chatContainerRef.current) return;

    // Scroll to bottom only when a new message is added or typing is complete
    if (!isTyping) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isTyping]);

  useEffect(() => {
    if (!listening && isRecording) {
      setTimeout(() => handleSubmitFromSpeech(transcript), 1000);
      setIsRecording(false);
    }
  }, [listening]);

  const handlePromptChange = (event) => setPrompt(event.target.value);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!prompt.trim()) return;
    await sendRequest(prompt);
  };

  const handleSubmitFromSpeech = async (speechText) => {
    if (!speechText.trim()) return;
    setPrompt(speechText);
    await sendRequest(speechText);
  };

  const sendRequest = async (text) => {
    setLoading(true);
    setError(null);
    setIsTyping(true);

    if (!apiKey) {
      setError("API Key is missing. Check your .env file.");
      setLoading(false);
      setIsTyping(false);
      return;
    }

    try {
      setMessages((prev) => [...prev, { role: "user", text }]);
      setPrompt("");
      const res = await axios.post(`${apiEndpoint}?key=${apiKey}`, { contents: [{ parts: [{ text }] }] }, { headers: { "Content-Type": "application/json" } });
      res.data.candidates?.length > 0 ? simulateTypingEffect(res.data.candidates[0].content.parts[0].text) : setError("No valid response from the AI.");
    } catch (error) {
      console.error("Network Error:", error);
      setError(error.response?.data?.error?.message || "Error connecting to API");
    } finally {
      setLoading(false);
    }
  };

  const simulateTypingEffect = (responseText) => {
    setTypingResponse("");
    let index = 0;
    typingInterval.current = setInterval(() => {
      if (index < responseText.length) {
        setTypingResponse((prev) => prev + responseText[index++]);
      } else {
        clearInterval(typingInterval.current);
        setMessages((prev) => [...prev, { role: "bot", text: responseText }]);
        setTypingResponse("");
        setIsTyping(false);
      }
    }, 25);
  };

  const handleStop = () => {
    if (isRecording) SpeechRecognition.stopListening();
    if (isTyping) clearInterval(typingInterval.current);
    setIsRecording(false);
    setTypingResponse("");
    setIsTyping(false);
    setLoading(false);
  };

  return (
    <div className="h-screen flex flex-col items-center bg-gray-100 pt-36">
      <div className="w-full max-w-2xl flex flex-col h-[80vh] bg-white rounded-lg shadow-lg p-4 overflow-hidden">
        <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4 space-y-3 pb-20">
          {messages.map((msg, index) => (
            <div key={index} className={`p-3 rounded-lg max-w-[75%] ${msg.role === "user" ? "bg-blue-500 text-white ml-auto" : "bg-gray-300 text-black mr-auto"}`}>
              <ReactMarkdown className="mt-1">{msg.text}</ReactMarkdown>
            </div>
          ))}
          {typingResponse && (
            <div className="p-3 rounded-lg max-w-[75%] bg-gray-300 text-black mr-auto">
              {/* Render plain text during typing */}
              <div>{typingResponse}</div>
            </div>
          )}
          {loading && (
            <div className="p-3 rounded-lg max-w-[75%] bg-gray-300 text-black mr-auto flex items-center">
              <AiOutlineLoading3Quarters className="animate-spin mr-2" /> Searching...
            </div>
          )}
          <div ref={messagesEndRef}></div>
        </div>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        <form onSubmit={handleSubmit} className="flex mt-2">
          <input className="flex-1 p-3 border rounded-l focus:outline-none" type="text" value={prompt} onChange={handlePromptChange} placeholder="Type your message..." />
          <button
            className={`font-bold py-3 px-5 rounded-r 
    ${isRecording || isTyping ? "bg-red-500" : "bg-blue-500"} text-white`}
            onClick={() => {
              if (isTyping) return handleStop(); // Stop response generation
              if (prompt) return; // Prevent toggling mic when input has text

              if (isRecording) {
                SpeechRecognition.stopListening();
                setIsRecording(false);
              } else {
                SpeechRecognition.startListening();
                setIsRecording(true);
              }
            }}
          >
            {isTyping ? <IoStop /> : isRecording ? <IoStop /> : prompt ? <IoSend /> : <FaMicrophone />}
          </button>
        </form>
      </div>
    </div>
  );
};

export default TalkWithAi;