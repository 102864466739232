export const testimonials = [
  {
    name: "Wajiha, Wahaj",
    age: "4, 2",
    city: "Lahore",
    review:
      "Asslam u alaikum I have joined MBU homeschooling for my kids 4 Years ,2 Years This group is very helpful for the parents who want to teach their child themself Mam is very kind women.She is helping us without any pay and serving For us......Ma Sha Allah aur h bilkul free bi h koi fees ni h Inki Activities bohat helpful h.Meray bachon n bhohat improve kia h jb s m n ye group join kia h ",
  },
  {
    name: "Zainab zahid",
    age: 4,
    city: "Sargodha",
    review:
      "Bht Acha platform ha. Ma sha Allah bht khuch new learn krna k moqa mila or aga bachao ko bi learn krwana ma bht help mili.ap ki full attention ki wjh sa kam sikhna or karna ma bi bht maza ata.outstanding effort and well done work .",
  },
  {
    name: "M.Shazain",
    age: 4,
    city: "Lahore",
    review:
      "Asslam o alikum My son have some health issues os Liye ma preshan thi school b nai bej sakti thi but now I follow weekly plans mere bety ne buht improve Kiya h I'm really grateful to this platform Meri trha buht sari mothers hai Jo ye issues suffer kr rhi hai ma jb b apne bety ko dekhti hu dill se Dua krti hu because mje smj nai ati thi ma kaha se start kru thank you so much to MBU homeschooling tea",
  },
  {
    name: "M.Hussain.ilahi ",
    age: 3,
    city: "Lahore",
    review:
      "Aoa MBU homeschooling is best platform mam mein ap ko salute paish krna chahti hun ap k platform Sy mujy Boht rehnumai hasil hui Boht acha work krwa rai ap bachun ko aur har parents ko time py guide kr rai hain Allah ap ko ajar dain Ameen ",
  },
  {
    name: "Iqra Sohail Ahmad ",
    age: "Unknown",
    city: "Gujranwala",
    review:
      "Aoa... Main Abdul Hadi (4 years) or Abdul Ahad (2 years) ki mother hn main apny dono Beto ko Alhamdulilah MBU Home schooling k through home schooling krwa r hn bht acha platform h ye bchy activity ka name sunty e happy ho jaty h or happily  involve hty h kaam krny k liye study ka burden ni h bcha  easily pick krta or Dua h k ye Pakistan ka no.1 platform bny .isko join kr k mothers ghr bhety apny bachon ko active (bht kch sekha skti h ) itna acha platform h k mri  apni family me se b mothers ne isko join Kia Alhamdulilah ❤️ Ma'am I appreciate u Allah apki trf brhny waly hr sher ko Khair m badal dy mashallah ap SB k msgs ka reply krti h achy se guide krti h itni busy life m (SB k comments ko prhna or reply krna )well done  Jazakillah o khaira",
  },
  {
    name: "M Hassan Ali",
    age: 3,
    city: "Multan",
    review:
      "Assalamualaikum everyone Mera beta 3 years ka hy me ne pehly tuition start ke lakin wahan pr bachon ko bohat problems the k mama pass nhe hain akely nhe ja rhy thy or study krna un k ley mushkil Kam hota ja rha tha phr Meri sister ne MBU Homeschooling ka link btaya to me JB se Start kia hy me satisfy hon study se easy work hy activities se bachy enjoy bhe krty hain or 1 plate form mila hy proper syllables hy or bachy bhe easy rahty Hain ghr me or mother apny bachon k mind k mutabik unhen batr handle kr skti Hain in plate form k according Allah Tala is plate form ko bohat taraqi dy or mam or un ke team ko Ameen",
  },
  {
    name: "Nusaibah tahira",
    age: 2,
    city: "Islamabad",
    review:
      "MUB is a great platform for homeschooling my daughter is 2 years old but she learns alot when she will be able to join regular school she will familiar about different topics in sha Allah through their different activities we can protect our children  from screen it is very good thing may Allah progress day by day this system  ameen 🥰🥰",
  },
  {
    name: "Muhmd Aahil munir",
    age: 3,
    city: "chiniot",
    review:
      "G mam bhtttt helpful hy ye platform i have no words k ksy mention kron i am so worried about My son now i am easy jab sy ap ka group join kia hy mery betay ko trace krna ni ata tha ab wo mashallh bhttt acha krta hy or sb sy barh k phonic amazing ❤️",
  },
  {
    name: "Muhammad  tahoor",
    age: 4,
    city: "Gujrat",
    review:
      "السلام علیکم MBU ik free plateform hai isy har koi jion kr skta hai is ma bachy  activities  ki vja sa zyada intrest laty hai bachy bht shook sa study  krty  hai is ma mam ka bht krdar hai vo free  Allah ki rza ky leay  sub ko time dati hai  jo oford nhi krskty vo b  tleem hasil kr sky  or mam  mhnat sa work ready kr ka send krte hai is sa time ki b pabndi ho jati hai humari homeschooling ky bht sa fiday hai hmare countery ma dosray schools ma poray sal ma bht si Holidays hoti hai  hum pora sal bachoo ko Regular  school nhi bj paty homeschooling  sa hum daily prhaty hai school ma bachoo par os trha tvajo nhi di jati jistrha  mothers dati hai 7 years tk bachoo ko apny sath rakhny ka b hukam hai is sa bacha farmabardar or rishtoo ko shi sa janta hai dada dadi or bro ka ihtram sekhta hai or b bht sy Benefits  hai home schooling  ka but msg agy bht bra hogya",
  },
  {
    name: "Muhammad Hanzala",
    age: 3,
    city: "Unkown",
    review:
      "Assalam o alaikum! Mere betay ko pencil b nai pkrni ATI thi uski grip hi nai thi but jb se apko join kia AP k plans or activities ko follow kia he Mera Beta ab Boht Kuch seekh gya he or Boht Khush ho kr kaam krta. Boht acha platform he ye.thank you soo mush.",
  },
  {
    name: "Muhammad hashim",
    age: 3,
    city: "Unkown",
    review:
      "assalam o alaikum! masha Allah buht kuch learn kr rhy hen hmary bchy or hm mothers b buht acha platform h activities k zriy study krwany sy buht achy sy learn kr rhy hen Allah tala ap ko is ka ajr dy ameen.",
  },
  {
    name: "Atika Mirza 🌸",
    age: 3,
    city: "Unkown",
    review:
      "Asslam o Alikum Meri beti 3year ki hy or me school bhejny k leay bilkul satisfy nhe or proper learning k leay mjy idea bhe ho raha tha bohat fkr hoti the Jb MBU Home Schooling start ki hy me mutmain bhe q k es sy Atika me improvement bhe hoi hy...learing k sath sath difference activities bhe krai jati hain jo ye shok sy krti hy bore nhe hoti.. Es platform k bger jo mery leay difficult tha. Thank You so much MBU Homeschooling",
  },
  {
    name: "Mohib, Adam",
    age: "4, 2.5",
    city: "Unkown",
    review:
      " Aslamu alaikum.. Is group se bht help mili k bachu ko before school learning activities ki traf ksy involve kia jay.. R activities bht hi asan r home metrial based h koi v apny bachu ko ghr m hi perform krwa skta..sb se bri bat k itni mhnat r imandari se group admin is ko ly k chal rhi without any benefit just for our child! it's so satisfying and concerned.. Allah apko ajjar ata kry amn..",
  },
  {
    name: "Mashaim/Masham Murtaza ",
    age: "3, 4",
    city: "Unkown",
    review:
      "Assalam o alaikum .bht acha Kam kr Rahe ha AP  k group k zarye hamaray bachy ghr behtay achi talem hasil kr rahy ha .mja to bht help mil Rahe ha or mei bht satisfy hu .allah apko or hemat or hosla dy takay ap agy b is group ko or b achy se Chala sakein or hamaray bacho ko sekhny ko Mily ameen . ",
  },
  {
    name: "Unkown",
    age: "Unkown",
    city: "Unkown",
    review:
      "Asslam o alikum My son have some health issues os Liye ma preshan thi school b nai bej sakti thi but now I follow weekly plans mere bety ne buht improve Kiya h I'm really grateful to this platform Meri trha buht sari mothers hai Jo ye issues suffer kr rhi hai ma jb b apne bety ko dekhti hu dill se Dua krti hu because mje smj nai ati thi ma kaha se start kru thank you so much to MBU homeschooling team ",
  },
  {
    name: "Unkown",
    age: "Unkown",
    city: "Unkown",
    review:
      "My interior Sindh my rehti hon my apni betio ki early education k liye fikar mand thi or school nhi bhejna charahi thi phir myny inka group join kia In MBU homeschooling k weekly Plain ko follow karty howay apni betio ko home schooling kawa rahi hon I highly recommend & Appreciate Mem",
  },
  {
    name: "Unkown",
    age: "Unkown",
    city: "Unkown",
    review:
      "Aslam o alaikum m apny bcho ko school bhjny k lein satisfy ni hu or jis trha study bcho ki m chati thi wo ek achy school m wo hm afford ni kr skty is mehngai m to MBU homeschooling system ny y kam asan krdia k hm weekly plan sy ghr m quality education dy skty hai with alot of and activities  thanks for all team",
  },
  {
    name: "Unkown",
    age: "Unkown",
    city: "Unkown",
    review:
      "Assalamu alaikum bahut hi umda platform hai Maine bahut kuchh learn Kiya kindly ik gujarish hai kuchh activity autistic kids ke liye bhi share Kiya Karen",
  },
  {
    name: "Unkown",
    age: "Unkown",
    city: "Unkown",
    review:
      "Assalam o alaikum.this homeschooling plate form is outstanding plz join this group and make your kids better future",
  },
  {
    name: "Unkown",
    age: "Unkown",
    city: "Unkown",
    review:
      "Mujhy lgta tha may khud hi apny  bchun ko prha skti Lekin group join  Kya to kitny achy say week plan or activities din KY or sikhny ko Mila bchy bhi enjoy krty prhty Huey. Meny to apni sari family may Jo mother sb ko group may add kya",
  },
  {
    name: "Unkown",
    age: "Unkown",
    city: "Unkown",
    review:
      "MBU Homeschooling mothers k liye Allah paak ki boht barhi blessing hy specially rural areas ki mothers k liye jo ache schools ki fee afford nhn kr pati ya wo pre nursery k baare mn kuch nhn jaantn or ghr baithe apne bachon ka time waste krne ki bjaae boht kuch seekh b rhi hn or apne bachon ki base strong kr rhi hn relax mahole mn",
  },
  {
    name: "Unkown",
    age: "Unkown",
    city: "Unkown",
    review:
      "Assalam o alaikum I m child psychologist I have a experience with ADHD Autistic and various disable children. Now, I m a mother of two . My eldest baby girl is 2 year old . And I join this group for her. So firstly thank you so much to the  group admin. She did great work for our generations. Her motive and vision so big and her work is so elegant. She did great job . Hats off to you ❤️ It's all about sadqa e jarya...🫶🏻",
  },
];
