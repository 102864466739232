import React from "react";
import { StarOfMonthData } from "../assets/data/StarsofMonthData";
import EventCard from "../components/EventCard";

const StarOfMonth = () => {
  const groupedEvents = StarOfMonthData.reduce((acc, event) => {
    const monthYear = new Date(event.date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
    });
    if (!acc[monthYear]) acc[monthYear] = [];
    acc[monthYear].push(event);
    return acc;
  }, {});

  return (
    <div className="min-h-screen w-full bg-gray-100 p-6 pt-48">
      <div className="text-center md:text-left md:ml-12">
        <h1 className="text-2xl font-bold mb-2">Stars of the month at HomeschoolingWithMBU</h1>
        <span className="block w-32 h-1 bg-gradient-to-r from-blue-500 to-purple-500 mt-1 mb-4 mx-auto md:mx-0"></span>
      </div>
      {Object.entries(groupedEvents).map(([monthYear, monthEvents]) => (
        <div key={monthYear} className="mb-10 mt-10">
          <h2 className="text-xl font-semibold text-gray-800 border-b pb-2 mb-4">
            {monthYear} Stars
          </h2>
          <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 max-w-7xl mx-auto">
            {monthEvents.sort((a, b) => new Date(b.date) - new Date(a.date)).map((event, index) => (
              <EventCard key={index} event={event} />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default StarOfMonth;
