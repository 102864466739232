import React from "react";
import ScrollToTopButton from "../components/scrollTopButton";
import JoliPhonicComponent from "../components/JoliPhonicComponent";
import { JollyPhonicData } from "../assets/data/JollyPhonicData";

const JoliPhonics = () => {
  return (
    <div className="mt-44"> {/* Adjust the top margin */}
      <div className="text-center md:text-left md:ml-12">
        <h1 className="text-2xl font-bold mb-2">Jolly Phonics</h1>
        <span className="block w-32 h-1 bg-gradient-to-r from-blue-500 to-purple-500 mt-1 mb-4 mx-auto md:mx-0"></span>
      </div>

      {/* Render each group */}
      {JollyPhonicData.map((group) => (
        <div key={group.group} className="mb-8">
          {/* Group Title */}
          <div className="text-center">
            <h2
              className="text-xl font-bold text-white mb-2 py-2 px-4 rounded-lg inline-block mb-6"
              style={{ backgroundColor: "#ff5757" }}
            >
              Group {group.group}
            </h2>
          </div>

          {/* Render items in the group */}
          <div className="flex flex-wrap gap-4 justify-center md:justify-start md:ml-12">
            {group.items.map((phonic, index) => (
              <JoliPhonicComponent
                key={index}
                title={phonic.title}
                thumbnail={phonic.thumbnail}
                audioUrl={phonic.audioUrl}
              />
            ))}
          </div>
        </div>
      ))}

      {/* Circular button to scroll to top */}
      <ScrollToTopButton />
    </div>
  );
};

export default JoliPhonics;