import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="bg-black text-white py-8 mt-6">
      <div className="container mx-auto px-4 text-left">
        {/* Social Media Icons */}
        <div className="flex flex-wrap justify-start space-x-6 mb-6">
          <a href="https://www.facebook.com/share/1BZ4oxRYTM/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-500">
            <i className="fab fa-facebook fa-lg"></i>
          </a>
          <a href="https://www.instagram.com/homeschoolingwithmbu?igsh=MTVzYXRnampraDNjeA==" target="_blank" rel="noopener noreferrer" className="text-pink-500 hover:text-pink-400">
            <i className="fab fa-instagram fa-lg"></i>
          </a>
          <a href="https://www.threads.net/@homeschoolingwithmbu" target="_blank" rel="noopener noreferrer" className="text-purple-600 hover:text-purple-500">
            <i className="fab fa-threads fa-lg"></i>
          </a>
          <a href="https://www.tiktok.com/@homeschoolingwithmbu0?_t=ZG-8t7dfWkxytM&_r=1" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-gray-200">
            <i className="fab fa-tiktok fa-lg"></i>
          </a>
          <a href="https://www.linkedin.com/company/homeschoolingwithmbu/about" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
            <i className="fab fa-linkedin fa-lg"></i>
          </a>
        </div>

        {/* Navigation Links */}
        <div className="mb-6">
          <p className="text-xl font-semibold mb-4">Quick Links</p>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            <Link to="/" className="text-white hover:underline" onClick={handleLinkClick}>Home</Link>
            <Link to="/preschool" className="text-white hover:underline" onClick={handleLinkClick}>Preschool</Link>
            <Link to="/talk-ai" className="text-white hover:underline" onClick={handleLinkClick}>Talk with Ai</Link>
            <Link to="/jolly-phonics" className="text-white hover:underline" onClick={handleLinkClick}>Jolly Phonics</Link>
            <Link to="/islamic-gallery" className="text-white hover:underline" onClick={handleLinkClick}>Islamic Gallery</Link>
            <Link to="/star-of-the-month" className="text-white hover:underline" onClick={handleLinkClick}>Star Of the Month</Link>
            <Link to="/parents-feedback" className="text-white hover:underline" onClick={handleLinkClick}>What Parents Say</Link>
            <Link to="/printable" className="text-white hover:underline" onClick={handleLinkClick}>Printable</Link>
            <Link to="/games" className="text-white hover:underline" onClick={handleLinkClick}>Games</Link>
            <Link to="/learning-toys" className="text-white hover:underline" onClick={handleLinkClick}>Learning Toys</Link>
            <Link to="/curriculum" className="text-white hover:underline" onClick={handleLinkClick}>Curriculum</Link>
            <Link to="/videos" className="text-white hover:underline" onClick={handleLinkClick}>Videos</Link>
            <Link to="/youtube" className="text-white hover:underline" onClick={handleLinkClick}>YouTube</Link>
            <Link to="/pics" className="text-white hover:underline" onClick={handleLinkClick}>Pictures</Link>
            <Link to="/weekly-plan" className="text-white hover:underline" onClick={handleLinkClick}>Weekly Plan</Link>
          </div>
        </div>

        {/* Contact Information Section */}
        <div className="mb-6">
          <p className="text-xl font-semibold mb-4">Contact Information</p>
          <p>Email: <a href="mailto:homeschoolingwithmbu@gmail.com" className="text-blue-400 hover:text-blue-300 underline">homeschoolingwithmbu@gmail.com</a></p>
        </div>

        {/* Other Information */}
        <div className="mt-4 text-sm">
          <p className="text-gray-400">Copyright © 2025 Umer Rasheed</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
