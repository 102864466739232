export const JollyPhonicData = [
  {
    group: 1,
    items: [
      {
        title: `S`,
        audioUrl: require("../audios/s.mpeg"),
        thumbnail: 'https://res.cloudinary.com/dwa7vez9b/image/upload/v1741370894/9_srgnyr.jpg'
      },
      {
        title: `a`,
        audioUrl: require("../audios/a.mpeg"),
        thumbnail: 'https://res.cloudinary.com/dwa7vez9b/image/upload/v1741370841/10_gzoodq.jpg'
      },
      {
        title: `t`,
        audioUrl: require("../audios/t.mpeg"),
        thumbnail: 'https://res.cloudinary.com/dwa7vez9b/image/upload/v1741370841/11_iw2xff.jpg'
      },
      {
        title: `i`,
        audioUrl: require("../audios/i.mpeg"),
        thumbnail: 'https://res.cloudinary.com/dwa7vez9b/image/upload/v1741370842/12_knyuzr.jpg'
      },
      {
        title: `p`,
        audioUrl: require("../audios/p.mpeg"),
        thumbnail: 'https://res.cloudinary.com/dwa7vez9b/image/upload/v1741370842/13_utqdlf.jpg'
      },
      {
        title: `n`,
        audioUrl: require("../audios/n.mpeg"),
        thumbnail: 'https://res.cloudinary.com/dwa7vez9b/image/upload/v1741370842/14_e4suzt.jpg'
      }
    ]
  },
  // Add more groups here as needed
];