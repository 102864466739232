import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { useNavigate } from "react-router-dom";

const HomeCarouselModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const slides = [
    {
      text: "Jolly Phonics",
      link: "/jolly-phonics",
      image: "https://res.cloudinary.com/dwa7vez9b/image/upload/v1741489854/WhatsApp_Image_2025-03-09_at_8.08.07_AM_ro7her.jpg",
    },
    {
      text: "Coming Soon...",
      link: "/coming-soon",
      image: "https://res.cloudinary.com/dwa7vez9b/image/upload/v1741490350/WhatsApp_Image_2025-03-09_at_8.11.55_AM_nbiprf.jpg",
    },
    {
      text: "Talk to our Ai assistant",
      link: "/talk-ai",
      image: require("../assets/images/ai-image.webp"),
    },
    {
      text: "What Parents Say About Us",
      link: "/parents-feedback",
      image: require("../assets/images/parents-review.webp"),
    },
    {
      text: "Check Stars of the Month",
      link: "/star-of-the-month",
      image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739212587/muhammad_bin_umer_fhh5it.jpg",
    },
  ];

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 mt-12">
        <div className="bg-white p-4 rounded-2xl shadow-lg w-[90%] max-w-md">
          {/* Close Button */}
          <div className="flex justify-end mb-4">
            <button
              className="bg-gray-200 text-black text-xl font-bold w-10 h-10 flex items-center justify-center rounded-full shadow-md border border-gray-300 hover:bg-gray-300 transition-all"
              onClick={() => setIsOpen(false)}
            >
              ✖
            </button>
          </div>

          {/* Swiper Carousel */}
          <Swiper
            navigation={true}
            loop={true} // Infinite looping
            autoplay={{
              delay: 3000, // 3 seconds
              disableOnInteraction: false, // Keeps autoplay even if user interacts
              pauseOnMouseEnter: false, // Prevents pausing on hover
            }}
            modules={[Navigation, Autoplay]}
            className="mySwiper !pb-6"
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div
                  className="h-56 sm:h-72 flex flex-col items-center justify-center bg-gray-100 rounded-lg cursor-pointer shadow-md relative overflow-hidden"
                  onClick={() => {
                    setIsOpen(false);
                    navigate(slide.link);
                  }}
                >
                  {/* Image filling the top-left corner */}
                  <img
                    src={slide.image}
                    alt={slide.text}
                    className="w-full h-full object-cover absolute top-0 left-0"
                  />

                  {/* Text in the bottom-right corner */}
                  <div className="absolute bottom-2 right-2 sm:bottom-4 sm:right-4 bg-black bg-opacity-50 text-white p-2 rounded-lg text-sm sm:text-lg font-semibold">
                    {slide.text}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    )
  );
};

export default HomeCarouselModal;