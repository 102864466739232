import React from 'react';

const IslamicGallery = () => {
  const pageStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    background: 'linear-gradient(135deg, #6a11cb, #2575fc)', // Gradient background
    color: '#fff', // White text
    fontFamily: '"Poppins", sans-serif', // Modern font
  };

  const textStyle = {
    fontSize: '3rem', // Larger text
    fontWeight: 'bold',
    animation: 'fadeIn 2s ease-in-out', // Fade-in animation
  };

  const stayTunedStyle = {
    fontSize: '1.5rem',
    marginTop: '1rem',
    animation: 'bounce 2s infinite', // Bounce animation
  };

  return (
    <div style={pageStyle}>
      <div>
        <p style={textStyle}>Coming Soon...</p>
        <p style={stayTunedStyle}>Stay Tuned!</p>
      </div>

      {/* Add some CSS animations */}
      <style>
        {`
          @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }

          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
            40% { transform: translateY(-20px); }
            60% { transform: translateY(-10px); }
          }
        `}
      </style>
    </div>
  );
};

export default IslamicGallery;