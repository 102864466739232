export const StarOfMonthData = [
  {
    image: "https://res.cloudinary.com/dwa7vez9b/image/upload/v1741632996/17_nhtlcs.jpg",
    name: "Muhammad Ismail Azeem",
    date: "Feb, 2025"
  },
  {
    image: "https://res.cloudinary.com/dwa7vez9b/image/upload/v1741632996/16_oisboa.jpg",
    name: "Eshal Fatima",
    date: "Feb, 2025"
  },
  {
    image: "https://res.cloudinary.com/dwa7vez9b/image/upload/v1741632306/8_frkiik.jpg",
    name: "Wajiha Fatima",
    date: "Feb, 2025"
  },
  {
    image: "https://res.cloudinary.com/dwa7vez9b/image/upload/v1741632310/11_xrcfog.jpg",
    name: "Muhammad Bin Ali",
    date: "Feb, 2025"
  },
  {
    image: "https://res.cloudinary.com/dwa7vez9b/image/upload/v1741632305/9_jqjbxr.jpg",
    name: "Yumna Usman",
    date: "Feb, 2025"
  },
  {
    image: "https://res.cloudinary.com/dwa7vez9b/image/upload/v1741632305/15_zmufc8.jpg",
    name: "Jaiden Yousaf",
    date: "Feb, 2025"
  },
  {
    image: "https://res.cloudinary.com/dwa7vez9b/image/upload/v1741632305/10_txbkxh.jpg",
    name: "Muhammad Tahoor",
    date: "Feb, 2025"
  },
  {
    image: "https://res.cloudinary.com/dwa7vez9b/image/upload/v1741632304/13_rulfro.jpg",
    name: "Irha Khan",
    date: "Feb, 2025"
  },
  {
    image: "https://res.cloudinary.com/dwa7vez9b/image/upload/v1741632304/14_ininfl.jpg",
    name: "Hamna Basit",
    date: "Feb, 2025"
  },
  {
    image: "https://res.cloudinary.com/dwa7vez9b/image/upload/v1741632304/12_mtqda6.jpg",
    name: "Khubaib younus",
    date: "Feb, 2025"
  },
  {
    image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739212587/muhammad_bin_umer_fhh5it.jpg",
    name: "Muhammad bin Umer",
    date: "Jan, 2025"
  },
  {
    image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739212851/Muhammad_tahoor_xoeswv.jpg",
    name: "Muhammad Tahoor",
    date: "Jan, 2025"
  },
  {
    image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739212969/Muhammad_zohan_y6aocn.jpg",
    name: "Muhammad Zohan",
    date: "Jan, 2025"
  },
  {
    image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739213308/Muhammad_bin_Ali_kgtka6.jpg",
    name: "Muhammad bin Ali",
    date: "Jan, 2025"
  },
  {
    image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739213722/Ayesha_salman_zdhdht.jpg",
    name: "Ayesha Salman",
    date: "Jan, 2025"
  },
  {
    image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739214137/Abdul_hadi_k2khuw.jpg",
    name: "Abdul Hadi",
    date: "Jan, 2025"
  },
  {
    image: "https://res.cloudinary.com/dgybxrkh2/image/upload/v1739214503/Fatima_Adil_gzfmce.jpg",
    name: "Fatima Adil",
    date: "Jan, 2025"
  },
]